/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import {
  RESOURCE_COMPANIES,
  RESOURCE_PEOPLE, RESOURCE_PEOPLE_LISTS,
  RESOURCE_EVENT_AGENDAS,
  RESOURCE_PEOPLE_LISTS_PERMISSIONS,
  RESOURCE_SHARED_COMPANIES_LISTS,
  RESOURCE_PROFILE_REPORTS,
  RESOURCE_CALENDARS,
  RESOURCE_TEAM_NOTES,
  API_BASE,
  INVITE_PEER_PSEUDO_RESOURCE,
  INVITE_CONTACT_PSEUDO_RESOURCE,
  RESOURCE_PROFILE_EMAILS,
  RESOURCE_CONNECTIONS,
  REQUEST_INTRO_PUBLIC_PSEUDO_RESOURCE,
  RESOURCE_RESOURCES_VIDEOS,
  SHARE_PUBLIC_PSEUDO_RESOURCE,
  RESOURCE_IFS,
  RESOURCE_IFS_MATCHES,
  RESOURCE_IFS_MEETS_HISTORY,
  RESOURCE_IFS_INVITATIONS,
  RESOURCE_IFS_MEETS,
  RESOURCE_PERKS,
  RESOURCE_PERK_CATEGORIES,
  RESOURCE_SIGNATURES,
  RESOURCE_INVESTMENT_ROUNDS,
} from 'containers/App/constants';

export const LOAD_FORM_SCHEMA = 'alchemist/Forms/LOAD_FORM_SCHEMA';
export const SET_FORM_SCHEMA = 'alchemist/Forms/SET_FORM_SCHEMA';
export const REGISTER_FIELD_POSITION = 'alchemist/Forms/REGISTER_FIELD_POSITION';
export const SET_USER_BY_EMAIL = 'alchemist/Forms/SET_USER_BY_EMAIL';
export const SET_COMPANY_BY_NAME = 'alchemist/Forms/SET_COMPANY_BY_NAME';
export const FORM_SECTION_SAVED = 'alchemist/Forms/FORM_SECTION_SAVED';
export const SAVE_FORM_SECTION = 'alchemist/Forms/SAVE_FORM_SECTION';
export const SAVE_PEOPLE_EXPERIENCE_SECTION = 'alchemist/Forms/SAVE_PEOPLE_EXPERIENCE_SECTION';
export const SAVE_PEOPLE_PORTFOLIO_SECTION = 'alchemist/Forms/SAVE_PEOPLE_PORTFOLIO_SECTION';
export const SAVE_GEN_INFO_SECTION = 'alchemist/Forms/SAVE_GEN_INFO_SECTION';

export const SAVE_COMPANY_PORTFOLIO_SECTION = 'alchemist/Forms/SAVE_COMPANY_PORTFOLIO_SECTION';
export const SAVE_COMPANY_COACHES_SECTION = 'alchemist/Forms/SAVE_COMPANY_COACHES_SECTION';
export const SAVE_COMPANY_FOUNDERS_SECTION = 'alchemist/Forms/SAVE_COMPANY_FOUNDERS_SECTION';
export const SAVE_COMPANY_INVESTORS_SECTION = 'alchemist/Forms/SAVE_COMPANY_INVESTORS_SECTION';
export const SAVE_COMPANY_INVESTMENT_ROUNDS_SECTION = 'alchemist/Forms/SAVE_COMPANY_INVESTMENT_ROUNDS_SECTION';

export const SEND_TEAM_INVITE = 'alchemist/Forms/SEND_TEAM_INVITE';
export const SAVE_INVITE_COMPANY = 'alchemist/Forms/SAVE_INVITE_COMPANY';
export const SAVE_SETTINGS = 'alchemist/Forms/SAVE_SETTINGS';
export const SAVE_SHARED_COMPANIES_LIST = 'alchemist/Forms/SAVE_SHARED_COMPANIES_LIST';
export const SEND_SHARED_COMPANIES_FOUNDER_INVITES = 'alchemist/Forms/SEND_SHARED_COMPANIES_FOUNDER_INVITES';
export const PREVIEW_SHARED_COMPANIES_LIST = 'alchemist/Forms/PREVIEW_SHARED_COMPANIES_LIST';
export const SAVE_SHARED_COMPANIES_LIST_DRAFT = 'alchemist/Forms/SAVE_SHARED_COMPANIES_LIST_DRAFT';
export const SEND_SHARED_COMPANIES_LIST = 'alchemist/Forms/SEND_SHARED_COMPANIES_LIST';
export const SEND_PASSWORD_CHANGE = 'alchemist/Forms/SEND_PASSWORD_CHANGE';

export const SAVE_DEMODAY_ATTENDANCE_SECTION = 'alchemist/Forms/SAVE_DEMODAY_ATTENDANCE_SECTION';
export const SAVE_PEOPLE_ACCOUNT_SECTION = 'alchemist/Forms/SAVE_PEOPLE_ACCOUNT_SECTION';
export const SAVE_MENTOR_SECTION = 'alchemist/Forms/SAVE_MENTOR_SECTION';
export const SAVE_FORM_WITH_EMAILS = 'alchemist/Forms/SAVE_FORM_WITH_EMAILS';

// Sample resources
/* eslint-disable object-property-newline */
/* eslint-disable object-curly-newline */
export const SAMPLE_INVESTMENT = {
  type: 'investments',
  attributes: { board_member: 0, lead: 0, verified: 0 },
  relationships: { investing_company: 0, investment_round: 0, investor_profile: 0 },
};
export const SAMPLE_INVESTMENT_ROUND = {
  type: 'investment_rounds',
  attributes: { announced: 0, raised: 0, stage: 0 },
  relationships: { company: 0 },
};
export const SAMPLE_MEMBERSHIP = {
  type: 'membership',
  attributes: {
    title: 0, is_primary: 0, is_deleted: 0, start_date: 0, end_date: 0, summary_of_work: 0, relation: 0, verified: 0,
    source: 0, source_id: 0, additional_info: 0,
  },
  relationships: { company: 0, user: 0 },
};
export const SAMPLE_PROFILE_EMAIL = {
  type: RESOURCE_PROFILE_EMAILS,
  attributes: { email: 0, is_primary: 0, is_deleted: 0 },
  relationships: { user: 0 },
};
export const SAMPLE_MEETING = {
  type: RESOURCE_CALENDARS,
  attributes: { weekday: 0, start_time: 0, end_time: 0, event_frequency: 0 },
  relationships: { user: 0 },
};
export const SAMPLE_EVENT_AGENDA = {
  type: RESOURCE_EVENT_AGENDAS,
  attributes: { start: 0, end: 0, event_type: 0, description: 0, allow_remotely: 0, agenda_type: 0, long_description: 0 },
  relationships: { aclass: 0, ifs: 0 },
};
export const SAMPLE_PEOPLE_LIST_PERMISSION = {
  type: RESOURCE_PEOPLE_LISTS_PERMISSIONS,
  attributes: { update: 0, read: 0 },
  relationships: { user: 0, user_list: 0 },
};
export const SAMPLE_PEOPLE_LIST = {
  type: RESOURCE_PEOPLE_LISTS,
  attributes: { title: 0, description: 0, event_list: 0, admin_list: 0 },
  relationships: { users: [] },
};
export const SAMPLE_SHAREDLISTS = {
  type: RESOURCE_SHARED_COMPANIES_LISTS,
  attributes: {
    message_type: 0, message: 0, name: 0, sender: 0, subject: 0, draft: 0,
  },
  relationships: {
    members: [], recipients: [], bcc: [], cc: [],
  },
};
export const SAMPLE_SHAREDLISTS_FOUNDER_INVITE = {
  type: RESOURCE_SHARED_COMPANIES_LISTS,
  attributes: { sender: 0, subject: 0, message: 0 },
  relationships: { poc_recipients: [], bcc: [], cc: [] },
};
export const SAMPLE_PERSON = {
  type: RESOURCE_PEOPLE,
  attributes: {
    profileimg: 0, firstname: 0, lastname: 0, title: 0, email: 0, phone: 0, skype_id: 0, country: 0, city: 0, state: 0,
    twitter: 0, linkedin_profile: 0, angelco: 0, website: 0, bragline: 0, exits_100m: 0, program_interest: 0, fmp: 0,
  },
  relationships: { roles: [], tags: [], angel_prefer_geographies: [], location: 0 },
};
export const SAMPLE_PERSON_FOR_ROLES = {
  type: RESOURCE_PEOPLE,
  attributes: {
    mentor_type: 0, exits_100m: 0, investmentcriteria: 0, angel_averagecheck: 0, angel_maxcheck: 0,
    angel_mincheck: 0, angel_investmentfrequency: 0, angel_preferlead: 0, angel_addvalue: 0, fund_decisionmaker: 0,
    fund_company_term_sheet: 0, service_addvalue: 0, service_alchemistoffer: 0, service_offer_email_or_link: 0,
    press_ideal_story: 0, fmp_roletype: 0, fmp_resume: 0, fmp_attractivecompany: 0, fmp_roletype_other: 0,
    fmp_comment: 0, fmp_preferredstage: 0, fmp_expertise: 0, timezone: 0, notes: 0, gmail_connected: 0,
    acquisition_areas_industries_of_focus: 0, attractive_acquisition: 0, acquisition_decisionmaker: 0,
    lecturer_bio: 0, lecturer_talk_title: 0, assistant_contact_info: 0, accredited_investor: 0,
  },
  relationships: {
    not_areas_of_interest: [], areas_of_interest: [], areas_of_service: [], press_topics: [], meetings: [], angel_prefer_geographies: [], location: 0,
  },
};
export const STEP_TWO_SAMPLE_PERSON_FOR_ROLES = {
  type: RESOURCE_PEOPLE,
  attributes: {
    profileimg: 0, firstname: 0, lastname: 0, title: 0, email: 0, phone: 0, skype_id: 0, country: 0, city: 0, state: 0,
    twitter: 0, linkedin_profile: 0, angelco: 0, website: 0, bragline: 0, program_interest: 0, fmp: 0,
    mentor_type: 0, exits_100m: 0, investmentcriteria: 0, angel_averagecheck: 0, angel_maxcheck: 0,
    angel_mincheck: 0, angel_investmentfrequency: 0, angel_preferlead: 0, angel_addvalue: 0, fund_decisionmaker: 0,
    fund_company_term_sheet: 0, service_addvalue: 0, service_alchemistoffer: 0, service_offer_email_or_link: 0,
    press_ideal_story: 0, fmp_roletype: 0, fmp_resume: 0, fmp_attractivecompany: 0, fmp_roletype_other: 0,
    fmp_comment: 0, fmp_preferredstage: 0, fmp_expertise: 0, timezone: 0, notes: 0, gmail_connected: 0,
    acquisition_areas_industries_of_focus: 0, attractive_acquisition: 0, acquisition_decisionmaker: 0,
    lecturer_bio: 0, lecturer_talk_title: 0, assistant_contact_info: 0, accredited_investor: 0,
  },
  relationships: {
    roles: [], tags: [], not_areas_of_interest: [], areas_of_interest: [], areas_of_service: [], press_topics: [], meetings: [], angel_prefer_geographies: [], location: 0,
  },
};
export const SAMPLE_COMPANY_MINIMAL = {
  type: RESOURCE_COMPANIES,
  attributes: { name: 0, source: 0, source_id: 0 },
};
export const SAMPLE_COMPANY = {
  type: RESOURCE_COMPANIES,
  attributes: {
    name: 0, logo: 0, description: 0, country: 0, adress: 0, city: 0, state: 0, website: 0,
    twitterhandle: 0, linkedin: 0, crunchbase: 0, angelcoprofile: 0,
    service_addvalue: 0, service_alchemistoffer: 0, service_offer_email_or_link: 0,
    fund_type: 0, fund_size: 0, fund_vintage_year: 0, fund_averagecheck: 0, fund_maxcheck: 0, fund_preferlead: 0,
    fund_mincheck: 0, fund_investmentfrequency: 0, fund_addvalue: 0, fund_dry_powder: 0, fund_last_closed_at: 0,
  },
  relationships: { areas_of_service: [], stages_of_interest: [], fund_prefer_geographies: [], aclass: 0 },
};
export const SAMPLE_INVITE_COMPANY = {
  type: RESOURCE_PEOPLE,
  attributes: { firstname: '', lastname: '', email: '', company_name: '' },
  relationships: { company: '', user: '', aclass: 0 },
};
export const SAMPLE_UPDATE_POC = {
  type: RESOURCE_COMPANIES,
  attributes: { name: '' },
  relationships: { point_of_contact_account: '' },
};
export const SAMPLE_INVITE_TEAMMEMBER = {
  type: RESOURCE_PEOPLE,
  attributes: { firstname: '', lastname: '', email: '', company__relation: '', linkedin_profile: '', existing: '', point_of_contact: '' },
  relationships: { company: '' },
};
export const SAMPLE_REPORT_PROFILE = {
  type: RESOURCE_PROFILE_REPORTS,
  attributes: { wrong_info: '', outdated_info: '', other: '', additional_info: '' },
  relationships: { reported_profile: 0 },
};
export const SAMPLE_ADD_CONTACT = {
  type: RESOURCE_PEOPLE,
  attributes: { firstname: '', lastname: '', email: '', linkedin_profile: '' },
  relationships: { company: 0 },
};
export const SAMPLE_INVITE_CONTACT_NON_ADMIN = {
  type: RESOURCE_PEOPLE,
  attributes: { firstname: '', lastname: '', email: '', linkedin_profile: 0, living_in_eu: 0 },
  relationships: { roles: [] },
};
export const SAMPLE_REQUEST_INTRO_PUBLIC = {
  type: RESOURCE_CONNECTIONS,
  attributes: { firstname: 0, lastname: 0, email: 0, linkedin_profile: 0, company_name: 0, message: 0, type: 0 },
  relationships: { company: 0 },
};
export const SAMPLE_SHARE_COMPANY_PUBLIC = {
  type: RESOURCE_CONNECTIONS,
  attributes: {
    from_firstname: 0, from_lastname: 0, from_email: 0, from_linkedin_profile: 0,
    to_firstname: 0, to_lastname: 0, to_email: 0, to_linkedin_profile: 0, living_in_eu: 0,
    type: 0 },
  relationships: { company: 0 },
};
export const SAMPLE_TEAM_NOTES = {
  type: RESOURCE_TEAM_NOTES, attributes: { content: '' }, relationships: { company: '', profile: '' },
};
export const SAMPLE_RESOURCE = {
  type: RESOURCE_RESOURCES_VIDEOS,
  attributes: { title: '', link: '', category: '', resource_type: '', description: '', url: '',
    link_to_related_document: '', video_created_at: '' },
  relationships: { author: '' },
};
/* eslint-enable */

export const SAMPLE_IFS = {
  type: RESOURCE_IFS,
  attributes: {
    event_date: '',
    investor_sequential_slots: 0,
    investor_capacity: 0,
    alumni_registration_deadline: '',
    angels_freetext_agenda: '',
    angels_registration_deadline: '',
    backers_freetext_agenda: '',
    backers_registration_deadline: '',
    demoing_class_registration_deadline: '',
    gold_circle_freetext_agenda: '',
    gold_circle_registration_deadline: '',
    new_class_registration_deadline: '',
    who_chooses: '',
    meet_links_are: '',
    admin_single_meet_link: '',
    investor_prep_link: '',
    board_meeting_link: '',
    investor_panel_link: '',
    founder_homebase_link: '',
    investor_feedback_1_description: '',
    investor_feedback_1_long_description: '',
    investor_feedback_2_description: '',
    investor_feedback_2_long_description: '',
    founder_feedback_1_description: '',
    founder_feedback_1_long_description: '',
    founder_feedback_2_description: '',
    founder_feedback_2_long_description: '',
  },
  relationships: {
    backers_event_agenda: '',
    gold_circle_event_agenda: '',
    angels_event_agenda: '',
    demoing_class_event_agenda: '',
    new_class_event_agenda: '',
    alumni_event_agenda: '',
  },
};

export const SAMPLE_IFS_MATCHES = {
  type: RESOURCE_IFS_MATCHES,
  attributes: {
    match_type: '',
    feedback_session: '',
  },
  relationships: {
    ifs: { data: { type: RESOURCE_IFS, id: 0 } },
    founder: { data: { type: RESOURCE_PEOPLE, id: 0 } },
    investor: { data: { type: RESOURCE_PEOPLE, id: 0 } },
  },
};

export const SAMPLE_IFS_MEETS_HISTORY = {
  type: RESOURCE_IFS_MEETS_HISTORY,
  attributes: {},
  relationships: {
    ifs: { data: { type: RESOURCE_IFS, id: 0 } },
    founder: { data: { type: RESOURCE_PEOPLE, id: 0 } },
    investor: { data: { type: RESOURCE_PEOPLE, id: 0 } },
  },
};

export const SAMPLE_IFS_INVITATIONS = {
  type: RESOURCE_IFS_INVITATIONS,
  attributes: {
    event_rating: 0,
    event_comments: '',
  },
};

export const SAMPLE_IFS_MEETS = {
  type: RESOURCE_IFS_MEETS,
  attributes: {
    // add match_quality field
    had_meeting: 0,
    rating: 0,
    feedback: '',
  },
};

export const SAMPLE_PERKS = {
  type: RESOURCE_PERKS,
  attributes: {
    action_link: '',
    website: '',
    endorsed: 0,
    endorsed_expiry: 0,
    public: 0,
    public_reason: '',
    process: '',
    cost: '',
    speciality: '',
    visibility: 'AA',
    // promotion: 0,
    // promotion_expiry: 0,
  },
  relationships: {
    user: { data: { type: RESOURCE_PEOPLE, id: 0 } },
    company: { data: { type: RESOURCE_COMPANIES, id: 0 } },
    categories: [],
  },
};

export const SAMPLE_PERK_CATEGORIES = {
  type: RESOURCE_PERK_CATEGORIES,
  attributes: {
    text: '',
  },
};

export const SAMPLE_SIGNATURES = {
  type: RESOURCE_SIGNATURES,
  attributes: {
    name: '',
    email: '',
    image: 0,
    position: '',
    website: '',
    closing_words: '',
    signature_type: '',
    linkedin: '',
    twitter: '',
    podcast: '',
  },
};

export const SAMPLE_MAPPER = {
  [RESOURCE_EVENT_AGENDAS]: SAMPLE_EVENT_AGENDA,
  [RESOURCE_PEOPLE_LISTS]: SAMPLE_PEOPLE_LIST,
  [RESOURCE_PROFILE_REPORTS]: SAMPLE_REPORT_PROFILE,
  [RESOURCE_TEAM_NOTES]: SAMPLE_TEAM_NOTES,
  [`${API_BASE}/${INVITE_CONTACT_PSEUDO_RESOURCE}`]: SAMPLE_INVITE_CONTACT_NON_ADMIN,
  [`${API_BASE}/${INVITE_PEER_PSEUDO_RESOURCE}`]: SAMPLE_INVITE_CONTACT_NON_ADMIN,
  [`${API_BASE}/${REQUEST_INTRO_PUBLIC_PSEUDO_RESOURCE}`]: SAMPLE_REQUEST_INTRO_PUBLIC,
  [`${API_BASE}/${SHARE_PUBLIC_PSEUDO_RESOURCE}`]: SAMPLE_SHARE_COMPANY_PUBLIC,
  [RESOURCE_RESOURCES_VIDEOS]: SAMPLE_RESOURCE,
  [RESOURCE_IFS]: SAMPLE_IFS,
  [RESOURCE_IFS_MATCHES]: SAMPLE_IFS_MATCHES,
  [RESOURCE_IFS_MEETS_HISTORY]: SAMPLE_IFS_MEETS_HISTORY,
  [RESOURCE_IFS_INVITATIONS]: SAMPLE_IFS_INVITATIONS,
  [RESOURCE_IFS_MEETS]: SAMPLE_IFS_MEETS,
  [RESOURCE_PERKS]: SAMPLE_PERKS,
  [RESOURCE_PERK_CATEGORIES]: SAMPLE_PERK_CATEGORIES,
  [RESOURCE_SIGNATURES]: SAMPLE_SIGNATURES,
  [RESOURCE_INVESTMENT_ROUNDS]: SAMPLE_INVESTMENT_ROUND,
};
