/*
 * People/Search
 */
import { combineReducers } from 'redux-immutable';
import { fromJS, OrderedMap } from 'immutable';

import { generateAllReducers } from 'utils/filter/reducer';

import {
  SET_INVITE_COMPANY_MODAL,
  SET_PEOPLE_LISTS_MODAL,
  MARK_PEOPLE_LIST,
  CLEAR_MARKED_PEOPLE_LISTS,
  PEOPLE_PAGE_IDENTIFIER,
  INCREASE_SEARCH_RESULTS_OFFSET,
  RESULTS_TO_ADD_PER_ROUND,
  RESET_SEARCH_RESULTS_OFFSET,
  ADD_MARKED_PEOPLE_TO_MARKED_LISTS,
  SET_MERGE_MODAL,
  SELECT_MERGE_TARGET, REQUEST_MERGE, MERGE_SUCCESS, MERGE_FAILED, OPEN_MOBILE_FILTERS_MODAL, CLOSE_MOBILE_FILTERS_MODAL, TOGGLE_ACCORDION_ITEM,
  SET_INVITE_CLASS_COACH_MODAL,
  TOGGLE_PEOPLE_FILTERS,
  SET_QS_FOR_EXPERIENCES_SEARCH,
} from './constants';

const initialPeopleUiState = fromJS({
  isInviteCompanyOpen: false,
  isInviteClassCoachOpen: false,
  mobileFiltersModalIsOpen: false,
  openAccordionItems: [],
  filtersExpanded: false,
  qsForExperiencesSearch: null,
});

function peopleUi(state = initialPeopleUiState, action) {
  switch (action.type) {
    case SET_INVITE_COMPANY_MODAL:
      return state.set('isInviteCompanyOpen', action.isOpen);
    case SET_INVITE_CLASS_COACH_MODAL:
      return state.set('isInviteClassCoachOpen', action.isOpen);
    case OPEN_MOBILE_FILTERS_MODAL:
      return state.set('mobileFiltersModalIsOpen', true);
    case CLOSE_MOBILE_FILTERS_MODAL:
      return state.set('mobileFiltersModalIsOpen', false);
    case TOGGLE_ACCORDION_ITEM:
      return state.update('openAccordionItems', (openItems) => openItems.includes(action.itemId)
        ? openItems.filter((item) => item !== action.itemId)
        : openItems.push(action.itemId));
    case TOGGLE_PEOPLE_FILTERS:
      return state.set('filtersExpanded', action.filtersState);
    case SET_QS_FOR_EXPERIENCES_SEARCH:
      return state.set('qsForExperiencesSearch', action.qs);
    default:
      return state;
  }
}

const initialPeopleListsModalState = fromJS({
  isPeopleListsOpen: false,
  isAddSearchResults: false,
  searchResultsOffset: 0,
  selectedPeopleLists: [],
  isSending: false,
});

function peopleListsModal(state = initialPeopleListsModalState, action) {
  switch (action.type) {
    case SET_PEOPLE_LISTS_MODAL:
      return state.set('isPeopleListsOpen', action.isOpen).set('isAddSearchResults', action.isAddSearchResults);
    case INCREASE_SEARCH_RESULTS_OFFSET:
      return state.update('searchResultsOffset', (offset) => offset + RESULTS_TO_ADD_PER_ROUND);
    case RESET_SEARCH_RESULTS_OFFSET:
      return state.set('searchResultsOffset', initialPeopleListsModalState.get('searchResultsOffset'));
    case CLEAR_MARKED_PEOPLE_LISTS:
      return state
        .set('selectedPeopleLists', initialPeopleListsModalState.get('selectedPeopleLists'))
        .set('isSending', false);
    case ADD_MARKED_PEOPLE_TO_MARKED_LISTS:
      return state.set('isSending', true);
    case MARK_PEOPLE_LIST:
      return state.update('selectedPeopleLists', (markedPeopleLists) => action.unselect
        ? markedPeopleLists.filter((objectRef) => objectRef.id !== action.markedPeopleListRef.id)
        : markedPeopleLists.push(action.markedPeopleListRef));
    default:
      return state;
  }
}

function mergeModal(state = initialPeopleListsModalState, action) {
  switch (action.type) {
    case SET_MERGE_MODAL:
      return state.set('isOpen', action.isOpen);
    case SELECT_MERGE_TARGET:
      return state.set('mergeTarget', action.targetRef);
    case REQUEST_MERGE:
      return state.set('isMerging', true);
    case MERGE_SUCCESS:
      return state.set('isMerging', false).set('isOpen', false);
    case MERGE_FAILED:
      return state.set('isMerging', false).set('error', action.error);
    default:
      return state;
  }
}

export default combineReducers({
  ...generateAllReducers(PEOPLE_PAGE_IDENTIFIER, { sort: new OrderedMap({ bayes_average_rating: -1 }) }),
  peopleUi,
  peopleListsModal,
  mergeModal,
});
