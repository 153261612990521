import { delay, call, put, select, takeLatest, debounce } from 'redux-saga/effects';
import get from 'lodash/get';

import { updateIncluded } from 'actions';
import { logError } from 'utils/log';
import { SET_QS_FOR_EXPERIENCES_SEARCH } from 'containers/People/Search/constants';

import { formatStr, updateLocation } from '../general';
import { extractData } from '../jsonApiExtract';
import { request } from '../request';
import { parseUrlFilters, putAction } from '../sagas';
import { generateFiltersActions, generateLoadedContentActions } from './actions';
import {
  INITIAL_LOAD,
  LOAD_CONTENT,
  LOAD_NEXT_PAGE,
  TOGGLE_SORT,
  SET_SORT,
  SET_CATEGORY,
  APPLY_TEMP_FILTERS,
  SET_ADV_FILTER,
  REMOVE_ADV_FILTER,
  APPLY_TEMP_FILTERS_KEY,
  CLEAR_FILTERS,
  SET_ADV_FILTERS_OPERATOR, APPLY_TEMP_FILTERS_GROUP, CLEAR_FILTERS_GROUP, SET_SEARCH, SET_SEARCH_OPERATOR,
} from './constants';
import { generateFiltersLocalString, generateFiltersString } from './filter';
import { generateFiltersSelectors, generateLoadedContentSelectors } from './selectors';

/**
 * Companies request/response handler
 */
export function* fetchObjects({
  isFetchNextPage, preprocessAppliedFilters, preprocessSort, baseClientUrl, baseApiUrl, pageIdentifier, pageSelector,
  filtersFirstChar = '&', fopOverride, controlClientUrl, nextPageFn = (p) => p + 1, filtersCustomSelector,
  loadedContentCustomSelector, loadedContentIdentifier, abortSignal, callback, contentMaxPageIsNeeded = false, _count, pageSize,
}) {
  const {
    setHasMore, loadNextPageSuccess, contentLoaded, setTotalCount, contentLoadingError, setContentMaxPage,
  } = generateLoadedContentActions(loadedContentIdentifier || pageIdentifier);
  const { setMaxPage } = generateFiltersActions(pageIdentifier || pageIdentifier);
  const { makeSelectFilter, makeSelectMaxPage } = generateFiltersSelectors(
    pageSelector || pageIdentifier,
    filtersCustomSelector
  );
  const { makeSelectLoading, makeSelectLoadingNextPage, makeSelectMaxPageContent } = generateLoadedContentSelectors(
    pageSelector || pageIdentifier,
    loadedContentCustomSelector
  );
  const { makeSelectTotalCount } = generateLoadedContentSelectors(
    pageSelector || pageIdentifier,
    loadedContentCustomSelector
  );

  // If a new selection is being loaded and next page is requested, wait for the new selection to finish loading
  // and vice-versa
  let isLoading = isFetchNextPage ? yield select(makeSelectLoading()) : yield select(makeSelectLoadingNextPage());
  while (isLoading) {
    yield delay(100);
    isLoading = isFetchNextPage ? yield select(makeSelectLoading()) : yield select(makeSelectLoadingNextPage());
  }

  // Select filters from store
  const filterImmutable = yield select(makeSelectFilter());

  const nextPage = isFetchNextPage ? nextPageFn(yield select(contentMaxPageIsNeeded ? makeSelectMaxPageContent() : makeSelectMaxPage())) : 0;

  const filterString = yield getFiltersString({
    isFetchNextPage,
    preprocessAppliedFilters,
    preprocessSort,
    pageSelector,
    filtersFirstChar,
    fopOverride,
    pageIdentifier,
    contentMaxPageIsNeeded,
    loadedContentCustomSelector,
    _count,
    pageSize,
  });

  const requestURL = `${baseApiUrl}${filterString}`;
  try {
    // Call our request helper (see 'utils/request')
    const resourcesRequest = yield call(request, requestURL, { signal: abortSignal });
    const { inclusions, items: objectRefs } = extractData(resourcesRequest);
    const objects = objectRefs.map((obj) => ({ ...obj, pageNumber: nextPage }));

    let countRequest = null;
    let availableResults = yield select(makeSelectTotalCount());
    if (!isFetchNextPage && _count === 'False') {
      countRequest = yield call(request, `${requestURL}&_only_count=True`);
      availableResults = get(countRequest, 'meta.results.available');
      yield put(setTotalCount(availableResults || 0));
    }

    const hasMore = _count === 'False' && pageSize ? availableResults > ((nextPage || 1) * pageSize) : !!resourcesRequest.links.next;
    yield put(setHasMore(hasMore));
    yield put(setMaxPage(nextPage));

    if (makeSelectMaxPageContent) {
      yield put(setContentMaxPage(nextPage));
    }

    yield put(updateIncluded(inclusions));
    yield isFetchNextPage ? put(loadNextPageSuccess(objects)) : put(contentLoaded(objects));

    if (!isFetchNextPage && !_count) {
      yield put(setTotalCount(get(resourcesRequest, 'meta.results.available') || 0));
    }
    if (!controlClientUrl || controlClientUrl === window.location.pathname) {
      updateLocation(`${baseClientUrl || window.location.pathname}${generateFiltersLocalString(filterImmutable)}`);
    }

    if (callback) {
      yield* callback(resourcesRequest);
    }

    return resourcesRequest;
  } catch (err) {
    logError(err);
    yield put(contentLoadingError(err));
    return err;
  }
}

export function* getFiltersString({
  isFetchNextPage, preprocessAppliedFilters, preprocessSort, pageSelector, filtersFirstChar = '&', fopOverride,
  pageIdentifier, contentMaxPageIsNeeded, loadedContentCustomSelector, _count, pageSize,
}) {
  const { makeSelectFilter, makeSelectMaxPage, selectTmpAdvFiltersOperator } = generateFiltersSelectors(pageSelector || pageIdentifier);

  const { makeSelectMaxPageContent } = generateLoadedContentSelectors(
    pageSelector || pageIdentifier,
    loadedContentCustomSelector
  );

  const filterImmutable = yield select(makeSelectFilter());
  const advFiltersOperator = yield select(selectTmpAdvFiltersOperator);
  const nextPage = isFetchNextPage ? (yield select(contentMaxPageIsNeeded ? makeSelectMaxPageContent() : makeSelectMaxPage())) + 1 : 0;
  const processedFilterImmutable = preprocessAppliedFilters
    && (yield preprocessAppliedFilters(filterImmutable.get('appliedFilters')));
  return generateFiltersString(
    processedFilterImmutable,
    preprocessSort && preprocessSort(filterImmutable.get('sort')),
    nextPage,
    filtersFirstChar,
    advFiltersOperator,
    fopOverride,
    _count,
    pageSize,
  );
}

export const getStandardSagas = ({
  pageIdentifier, baseApiUrl, controlClientUrl, preprocessAppliedFilters, preprocessSort, overrideFetchOptionsSaga, callback,
}) => {
  const { makeSelectAdvancedFilters } = generateFiltersSelectors(pageIdentifier);
  const { setFiltersAndSort } = generateFiltersActions(pageIdentifier);
  const { setSelectedObject, loadContent } = generateLoadedContentActions(pageIdentifier);

  const filtersFirstChar = baseApiUrl && (baseApiUrl.includes('?') ? '&' : '?');

  function* getObjects(isFetchNextPage) {
    let fetchOptionsOverride;
    if (overrideFetchOptionsSaga) {
      fetchOptionsOverride = yield overrideFetchOptionsSaga();
      if (fetchOptionsOverride === false) return;
    }
    yield fetchObjects({
      isFetchNextPage,
      preprocessAppliedFilters,
      preprocessSort,
      baseApiUrl,
      controlClientUrl,
      pageIdentifier,
      filtersFirstChar,
      callback,
      ...fetchOptionsOverride,
    });
  }

  function* loadContentOnOperatorChange() {
    const advFilters = yield select(makeSelectAdvancedFilters());
    if (advFilters?.length > 1) {
      yield put(loadContent());
    }
  }

  /**
   * Load the filters from the url search parameters
   */
  function* initialLoadSaga() {
    const queryParams = yield parseUrlFilters();
    yield put(setFiltersAndSort(queryParams.filter || {}, queryParams.sort));
    yield put(setSelectedObject(false));
    yield put(loadContent());
  }

  function* testExpSearchSaga() {
    const { makeSelectFilter } = generateFiltersSelectors(pageIdentifier);

    const filterImmutable = yield select(makeSelectFilter());
    const appliedFilters = filterImmutable.get('appliedFilters');
    const searchFilter = appliedFilters.get('search');
    if (searchFilter) yield put(loadContent());
  }

  return function* mainSaga() {
    yield takeLatest(formatStr(INITIAL_LOAD, pageIdentifier), initialLoadSaga);
    yield takeLatest(formatStr(LOAD_CONTENT, pageIdentifier), getObjects, false);
    yield takeLatest(formatStr(LOAD_NEXT_PAGE, pageIdentifier), getObjects, true);
    yield takeLatest(SET_QS_FOR_EXPERIENCES_SEARCH, testExpSearchSaga);
    /**
     * Only trigger the search when the user stops to breath
     */
    yield debounce(300, formatStr(SET_SEARCH, pageIdentifier), putAction, loadContent());
    yield takeLatest(
      [
        TOGGLE_SORT, SET_SORT, SET_CATEGORY, CLEAR_FILTERS, APPLY_TEMP_FILTERS, SET_ADV_FILTER, REMOVE_ADV_FILTER,
        APPLY_TEMP_FILTERS_KEY, APPLY_TEMP_FILTERS, APPLY_TEMP_FILTERS_GROUP, CLEAR_FILTERS_GROUP, SET_SEARCH_OPERATOR,
      ].map((actionType) => formatStr(actionType, pageIdentifier)),
      putAction,
      loadContent()
    );
    yield takeLatest(formatStr(SET_ADV_FILTERS_OPERATOR, pageIdentifier), loadContentOnOperatorChange);
  };
};

// export function* checkSelectedObject() {
//   const selectedObject = yield select(makeSelectSelectedObject());
//   if (!selectedObject) return;
//
//   const objects = yield select(makeSelectPeople());
//   yield put(setSelectedObject(objects.find((obj) => selectedObject.toJS().id === obj.id) || false));
// }
